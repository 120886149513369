export default [
  {
    path: '/avatar/profile',
    name: 'profile-avatar',
    meta: { activeItem: 'profile-avatar', permission: ['users.view_character'] },

    component: () => import('@/views/avatars/profile/ProfileAvatar.vue'),
  },
  {
    path: '/avatar/category',
    name: 'avatar-category',
    meta: { activeItem: 'avatar-category', permission: ['users.view_categorycharacter'] },

    component: () => import('@/views/avatars/categories/CategoriesAvatar.vue'),
  },
  {
    path: '/avatar/category/:id',
    name: 'avatar-subCategory',
    meta: { activeItem: 'avatar-category', permission: ['users.view_categorycharacter'] },

    component: () => import('@/views/avatars/categories/CategoriesAvatar.vue'),
  },
  {
    path: '/avatar/settings',
    name: 'settings-avatar',
    meta: { activeItem: 'settings-avatar', permission: ['users.menu_cms_character_settings', 'users.change_client'] },

    component: () => import('@/views/avatars/settings/SettingsAvatar.vue'),
  },

]
