export default [{
  path: '/users/administrators',
  name: 'administrators-list',
  meta: { permission: ['users.view_profile'] },

  component: () => import('@/views/users/administrators/AdministratorsList.vue'),
},
{
  path: '/users/administrators/edit/:id',
  name: 'administrators-edit',
  meta: { activeItem: 'administrators-list', permission: ['users.change_profile', 'users.view_profile'] },
  component: () => import('@/views/users/administrators/AdministratorsEdit.vue'),
},
{
  path: '/users/administrators/wizard',
  name: 'administrators-wizard',
  meta: { activeItem: 'administrators-list', permission: ['users.add_profile'] },
  component: () => import('@/views/users/administrators/FormAdminUser.vue'),
},
{
  path: '/users/final-users',
  name: 'final-users-list',
  meta: { permission: ['users.view_vuser'] },

  component: () => import('@/views/users/final-users/FinalUsersList.vue'),
},
{
  path: '/users/final-users/wizard',
  name: 'final-users-wizard',
  meta: { activeItem: 'final-users-list', permission: ['users.add_vuser'] },
  component: () => import('@/views/users/final-users/FormFinalUser.vue'),
},
{
  path: '/users/final-users/edit/:id',
  name: 'final-users-edit',
  meta: { activeItem: 'final-users-list', permission: ['users.view_vuser', 'users.change_vuser'] },
  component: () => import('@/views/users/final-users/FinalUsersEdit.vue'),
},
{
  path: '/users/profile/:id',
  name: 'profile-page',
  meta: { permission: ['users.change_profile', 'users.view_profile'] },

  component: () => import('@/views/users/profilePage.vue'),
},
]
